import { Fragment, useState, useCallback, useEffect, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  TrafficLayer,
} from "@react-google-maps/api";

import { LoadingCircle } from "../../../UI/spinners/LoadingSpinners";
import classes from "./MapPOIS.module.css";
import Title from "../../../UI/box/title/Title";
import {
  CustomMapHotspots,
  GoogleMapVehicles,
  GoogleMapWifiHotSpot,
  GoogleMapGarbage,
  GoogleMapHydroMeters,
  GoogleMapGatherRubish,
  GoogleMapMoveNav,
} from "./../../../map/GoogleMapStyles";

import purple_mark from "./../../../../assets/icons/Map/purple_mark_icon.svg";
import recycle_bin from "./../../../../assets/images/recycle_bin.png";

import { useStore } from "../../../../hooks/store";
import Entity from "../../../UI/box/entity/Entity";

const cityCenter = {
  lat: 38.9602106,
  lng: 20.7277997,
};
const initialTraffic = { avgSpeed: 0, avgTime: 0 };
const MapPOIS = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQlCnn8F1D0S9ymnWZwc_WFx2ZS6g8w1Q",
    // ...otherOptions
  });
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [infoWindowData, setInfoWindowData] = useState(null);

  const [startPoint, setStartPoint] = useState("-1");
  const [endPoint, setEndPoint] = useState("-2");
  const [navStart, setNavStart] = useState(false);

  const [traffic, setTraffic] = useState(initialTraffic);

  const state = useStore()[0];
  const typeRef = useRef(null);
  const mapStyleRef = useRef(null);
  const centerMapRef = useRef(cityCenter);

  useEffect(() => {
    if (state.types.length > 0) {
      for (let y = 0; y < state.types.length; y++) {
        if (state.types[y].id === props.typeId) {
          typeRef.current = state.types[y];
          break;
        }
      }
    }
  }, [state.types]);

  useEffect(() => {
    if (state.types.length > 0) {
      const data = state.sensors.filter((item) => item.typeId === props.typeId);
      for (let i = 0; i < data.length; i++) {
        if (data[i].position) {
          centerMapRef.current = data[i].position;
          break;
        }
      }
      setMarkers(data);
      console.log(data);
    }
  }, [state.types, state.sensors]);

  useEffect(() => {
    console.log(startPoint, endPoint);

    if (startPoint < 0 || endPoint < 0 || !navStart) return;

    const startIndex = markers.findIndex((item) => item.id === startPoint);
    const endIndex = markers.findIndex((item) => item.id === endPoint);

    if (startIndex < 0 || endIndex < 0) return;

    const fetchingData = async () => {
      setTraffic(initialTraffic);
      const response = await fetch(
        `https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=xBr1Nrejm9Mromq4M3HCvdU_gzeJ5mT3TA6KtwC9W8o&waypoint0=geo!${markers[startIndex].position.lat},${markers[startIndex].position.lng}&waypoint1=geo!${markers[endIndex].position.lat},${markers[endIndex].position.lng}&routeattributes=sm&mode=fastest;car;traffic:enabled`
      );

      const data = await response.json();

      console.log(data);
      const calcTitme = (
        data.response.route[0].summary.trafficTime / 60
      ).toFixed(0);
      const calcAvgSpeed = parseInt(
        (3.6 * data.response.route[0].summary.distance) /
          data.response.route[0].summary.trafficTime
      );
      setTraffic({
        avgSpeed: calcAvgSpeed,
        avgTime: calcTitme < 1 ? 1 : calcTitme,
      });
    };

    fetchingData();

    console.log(markers[startIndex], markers[endIndex]);
  }, [navStart]);

  useEffect(() => {
    if (props.typeId === "7") mapStyleRef.current = CustomMapHotspots;
    else if (props.typeId === "8") mapStyleRef.current = GoogleMapVehicles;
    else if (props.typeId === "4") mapStyleRef.current = GoogleMapWifiHotSpot;
    else if (props.typeId === "9") mapStyleRef.current = GoogleMapGarbage;
    else if (props.typeId === "10") mapStyleRef.current = GoogleMapHydroMeters;
    else if (props.typeId === "11") mapStyleRef.current = GoogleMapMoveNav;
    else if (props.typeId === "13") mapStyleRef.current = GoogleMapGatherRubish;
    else mapStyleRef.current = CustomMapHotspots;
  }, []);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(map);
  }, []);

  return (
    <Fragment>
      <Title
        className={classes.map_title_style}
        icon={typeRef.current && typeRef.current.icon}
        title={props.title}
        infoContext={typeRef.current && typeRef.current.tooltip}
        link={`/map?typeId=${props.typeId}`}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={props.typeId}
      />
      {isLoaded && (
        <div className={props.half ? classes.wrapper_half : classes.wrapper}>
          <GoogleMap
            center={centerMapRef.current}
            zoom={props?.zoom || 14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            
            options={{
              styles: mapStyleRef.current,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }}
          >
            {/* Child components, such as markers, info windows, etc. */}
            {props.typeId === "11" && <TrafficLayer autoUpdate />}
            <Fragment>
              {markers.map((marker) => (
                <Marker
                  key={marker.id}
                  position={marker.position}
                  animation={4}
                  clickable={true}
                  icon={
                    marker.smallMarker
                      ? marker.smallMarker
                      : props.typeId === "13"
                      ? recycle_bin
                      : purple_mark
                  }
                  onClick={() => {
                    setInfoWindowData(marker.id);
                  }}
                >
                  {infoWindowData && marker.id === infoWindowData && (
                    <InfoWindow>
                      <p>{marker.title}</p>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </Fragment>

            <></>
          </GoogleMap>
        </div>
      )}
      {props.half && (
        <form
          className={classes.form_selection}
          onSubmit={(event) => event.preventDefault()}
        >
          {!navStart && (
            <div className={classes.selection}>
              <p className={classes.nav_options}>Eπιλογή Διαδρομής</p>
              <select
                value={startPoint}
                onChange={(event) => setStartPoint(event.target.value)}
              >
                <option value={"-1"}>Αρχικό Σημείο</option>
                {markers.map((marker, index) => (
                  <option
                    key={index}
                    disabled={marker.id === endPoint}
                    value={marker.id}
                  >
                    {marker.title}
                  </option>
                ))}
              </select>

              <select
                value={endPoint}
                onChange={(event) => setEndPoint(event.target.value)}
              >
                <option value={"-2"}>Τελικός Προορισμός</option>
                {markers.map((marker, index) => (
                  <option
                    key={index}
                    disabled={marker.id === startPoint}
                    value={marker.id}
                  >
                    {marker.title}
                  </option>
                ))}
              </select>
            </div>
          )}
          {navStart && (
            <div>
              <Entity
                content={"Εκτιμώμενος Χρόνος ταξιδιού"}
                value={`${traffic.avgTime} Λεπτά`}
              />

              <Entity
                content={"Μέση Ταχύτητα"}
                value={`${traffic.avgSpeed} km/h`}
              />
            </div>
          )}

          <button
            disabled={
              endPoint === 0 || startPoint === 0 || endPoint === startPoint
            }
            onClick={() => {
              if (startPoint > 0 && endPoint > 0) setNavStart((prev) => !prev);
            }}
          >
            {navStart ? "Επαναφορά" : "Υπολογισμός χρόνου"}
          </button>
        </form>
      )}
      {!isLoaded && <LoadingCircle />}
    </Fragment>
  );
};

export default MapPOIS;
