import classes from "./SubSystemList.module.css";
import { useEffect, useState, useRef } from "react";
import Entity from "./../UI/panel/entity/Entity";
import { useStore } from "./../../hooks/store";
import { useHistory, useLocation } from "react-router-dom";

const SubSystemList = (props) => {
  const [selectSubSystem, setSelectSubSystem] = useState("");
  const [activeType, setActiveType] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [icons, setIcons] = useState([]);
  const state = useStore()[0];

  const history = useHistory();
  const { search } = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = new URLSearchParams(search).get("sensorId");

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        console.log(tempTypeIdActive, state.sensors);

        const data = state.sensors.filter(
          (item) => item.typeId === tempTypeIdActive[0].id
        );

        let activeSensor = "";
        let iconsEachSensor = {};
        for (let i = 0; i < data.length; i++) {
          if (currSensorURL && data[i].id.toString() === currSensorURL) {
            activeSensor = data[i];
          }
          iconsEachSensor = {
            ...iconsEachSensor,
            [data[i].id]: data[i].metrics.map((metric) => metric.icon_small),
          };
        }
        console.log(state.sensors);
        console.log(data);
        setActiveType(tempTypeIdActive[0]);
        setSensors(data);
        setIcons(iconsEachSensor);
        setSelectSubSystem(activeSensor);
      }
    }
  }, [search, state.sensors, state.types]);

  const clickMarkHandler = (item) => {
    if (item !== selectSubSystem) {
      history.push(`${pathname}?typeId=${activeType.id}&sensorId=${item.id}`);
      props.showAnimation(true);
    } else {
      props.showAnimation(false);
    }
  };

  console.log(selectSubSystem);

  let content = null;
  if (activeType) {
    content = (
      <div className={[classes.subsystem_layout].join(" ")}>
        <div
          className={[
            classes.flexContainer,
            classes.subsystem_title_style,
          ].join(" ")}
          style={{ backgroundColor: activeType.color }}
        >
          <p className={classes.subsystem_title}>{activeType.name}</p>
        </div>
        <div className={classes.list_place}>
          <ul>
            {sensors
              .filter((item) => {
                if (props.filterTypeName) {
                  if (
                    item.title
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toUpperCase()
                      .includes(
                        props.filterTypeName
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toUpperCase()
                      )
                  )
                    return item;
                } else {
                  return item;
                }
              })
              .filter((item) => {
                if (props.filtersVars.length === 0) return item;
                let inlcude = false;

                if (activeType.id === "9") {
                  console.log(item.metrics[4].value === "Πλήρεις");

                  for (let y = 0; y < props.filtersVars.length; y++) {
                    switch (props.filtersVars[y]) {
                      case "Άδειοι":
                        if (item.metrics[4].value <= "25%") return item;
                        break;
                      case "Κάτω από τη μέση":
                        if (
                          item.metrics[4].value > "25%" &&
                          item.metrics[4].value <= "40%"
                        )
                          return item;
                        break;
                      case "Περίπου στη μέση":
                        if (
                          item.metrics[4].value <= "60%" &&
                          item.metrics[4].value > "40%"
                        )
                          return item;
                        break;
                      case "Σχεδόν πλήρεις":
                        if (
                          item.metrics[4].value > "60%" &&
                          item.metrics[4].value <= "95%"
                        )
                          return item;
                        break;
                      case "Πλήρεις":
                        console.log("ΠΛΗΡΕΙΣ");
                        if (item.metrics[4].value > "95%") return item;
                        break;
                      default:
                        return {};
                    }
                  }
                } else {
                  for (let i = 0; i < item.metrics.length; i++) {
                    for (let y = 0; y < props.filtersVars.length; y++) {
                      if (item.metrics[i].content === props.filtersVars[y]) {
                        inlcude = true;
                        break;
                      }
                    }
                    if (inlcude) break;
                  }
                  if (inlcude) return item;
                }
              })
              .map((item) => (
                <li
                  key={item.id}
                  style={{ justifyContent: "flex-start" }}
                  className={
                    item.id === selectSubSystem.id
                      ? [classes.flexContainer, classes.active].join(" ")
                      : classes.flexContainer
                  }
                  onClick={() => {
                    clickMarkHandler(item);
                  }}
                >
                  <Entity
                    icon={activeType.icon}
                    colorIcon={activeType.id === "9" ? "blue" : "inherit"}
                    title={item.title}
                    address={activeType.id === "1" ? null : item.address}
                    icons={icons[item.id]}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
  return content;
};

export default SubSystemList;

// import classes from "./SubSystemList.module.css";
// import { useEffect, useState, useRef } from "react";
// import Entity from "./../UI/panel/entity/Entity";
// import { useStore } from "./../../hooks/store";
// import NavigationMenu from "../forms/horizontal/NavigationMenu";
// let start = false;
// const SubSystemList = (props) => {
//   const [selectSubSystem, setSelectSubSystem] = useState("");
//   const [activeType, setActiveType] = useState(null);
//   const [sensors, setSensors] = useState([]);
//   const [icons, setIcons] = useState([]);
//   const [state, dispatch] = useStore();
//
//   const [menu, setMenu] = useState({ id: 0, startPoint: null, endPoint: null });
//
//   useEffect(() => {
//     if (state.types.length > 0) {
//       const curActiveType = state.types.filter((type) => type.isActive);
//       if (curActiveType.length !== -1) {
//         setActiveType(curActiveType[0]);
//         setMenu((prev) => ({ ...prev, id: 0 }));
//       }
//     }
//   }, [state.types]);
//
//   useEffect(() => {
//     if (state.sensors.length > 0 && activeType) {
//       const data = state.sensors.filter(
//         (item) => item.typeId === activeType.id
//       );
//
//       let activeSensor = "";
//       let iconsEachSensor = {};
//       for (let i = 0; i < data.length; i++) {
//         if (data[i].isActive) {
//           activeSensor = data[i];
//         }
//         iconsEachSensor = {
//           ...iconsEachSensor,
//           [data[i].id]: data[i].metrics.map((metric) => metric.icon_small),
//         };
//       }
//       console.log(state.sensors);
//       console.log(data);
//       setSensors(data);
//       setIcons(iconsEachSensor);
//       setSelectSubSystem(activeSensor);
//     }
//   }, [state.sensors, activeType]);
//
//   const clickMarkHandler = (item) => {
//     if (menu.id === 1) {
//       if (menu.endPoint && menu.startPoint.id === item.id) {
//         start = true;
//         setMenu((prev) => ({
//           ...prev,
//           startPoint: prev.endPoint,
//           endPoint: item,
//         }));
//       } else if (
//         menu.startPoint &&
//         !menu.endPoint &&
//         menu.startPoint.id === item.id
//       )
//         setMenu((prev) => ({ ...prev, startPoint: null }));
//       else if (menu.endPoint && menu.endPoint.id === item.id) {
//         setMenu((prev) => ({ ...prev, endPoint: null }));
//       } else if (!menu.startPoint)
//         setMenu((prev) => ({ ...prev, startPoint: item }));
//       else if (!menu.endPoint) {
//         start = true;
//         setMenu((prev) => ({ ...prev, endPoint: item }));
//       }
//     }
//     if ((menu.id === 0 && item !== selectSubSystem) || start) {
//       dispatch("CLICKED_SENSOR", item.id);
//       setSelectSubSystem(item);
//       props.showAnimation(true);
//     } else {
//       props.showAnimation(false);
//     }
//     start = false;
//   };
//
//   const menuIdHandler = (id) => setMenu((prev) => ({ ...prev, id: id }));
//
//   const clearNavHandler = () =>
//     setMenu({ id: 1, startPoint: null, endPoint: null });
//
//   console.log(selectSubSystem);
//   console.log(menu);
//
//   let content = null;
//   if (activeType) {
//     content = (
//       <div className={classes.subsystem_layout}>
//         <div
//           className={[
//             classes.flexContainer,
//             classes.subsystem_title_style,
//           ].join(" ")}
//           style={{ backgroundColor: activeType.color }}
//         >
//           <p className={classes.subsystem_title}>{activeType.name}</p>
//         </div>
//         {activeType.id === "11" && (
//           <NavigationMenu
//             className={classes.sticky_nav}
//             menu={menu}
//             changeMenuId={menuIdHandler}
//             clear={clearNavHandler}
//           />
//         )}
//         <div
//           className={
//             activeType.id === "11"
//               ? [classes.list_place, classes.moreSpace].join(" ")
//               : classes.list_place
//           }
//         >
//           <ul>
//             {sensors
//               .filter((item) => {
//                 if (props.filterTypeName) {
//                   if (
//                     item.title
//                       .normalize("NFD")
//                       .replace(/[\u0300-\u036f]/g, "")
//                       .toUpperCase()
//                       .includes(
//                         props.filterTypeName
//                           .normalize("NFD")
//                           .replace(/[\u0300-\u036f]/g, "")
//                           .toUpperCase()
//                       )
//                   )
//                     return item;
//                 } else {
//                   return item;
//                 }
//               })
//               .filter((item) => {
//                 if (props.filtersVars.length === 0) return item;
//                 let inlcude = false;
//                 for (let i = 0; i < item.metrics.length; i++) {
//                   for (let y = 0; y < props.filtersVars.length; y++) {
//                     if (item.metrics[i].content === props.filtersVars[y]) {
//                       inlcude = true;
//                       break;
//                     }
//                   }
//                   if (inlcude) break;
//                 }
//                 if (inlcude) return item;
//               })
//               .map((item) => (
//                 <li
//                   key={item.id}
//                   style={{ justifyContent: "flex-start", position: "relative" }}
//                   className={
//                     item.id === selectSubSystem.id
//                       ? [classes.flexContainer, classes.active].join(" ")
//                       : classes.flexContainer
//                   }
//                   onClick={() => {
//                     clickMarkHandler(item);
//                   }}
//                 >
//                   <Entity
//                     icon={activeType.icon}
//                     title={item.title}
//                     address={item.address}
//                     icons={icons[item.id]}
//                   />
//                   {((menu.startPoint && menu.startPoint.id === item.id) ||
//                     (menu.endPoint && menu.endPoint.id === item.id)) && (
//                     <div
//                       className={classes.nav_badge}
//                       style={
//                         menu.startPoint && menu.startPoint.id === item.id
//                           ? { backgroundColor: "green" }
//                           : menu.endPoint && menu.endPoint.id === item.id
//                           ? { backgroundColor: "orange" }
//                           : {}
//                       }
//                     >
//                       <span>
//                         {menu.startPoint && menu.startPoint.id === item.id
//                           ? "A"
//                           : "B"}
//                       </span>
//                     </div>
//                   )}
//                 </li>
//               ))}
//           </ul>
//         </div>
//       </div>
//     );
//   }
//   return content;
// };
//
// export default SubSystemList;
