import classes from "./HorizontalGraph.module.css";
import search_icon from "../../../assets/icons/other/search_icon_24x24.png";
import { InputOnChange } from "../../UI/input/Input";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupMenuSubSystem from "../../UI/popupmenu/PopupMenuSubSystem";
import Badge from "@material-ui/core/Badge";
import PopupMenuVars from "../../UI/popupmenu/PopupMenuVars";
import { Fragment, useEffect, useRef, useState } from "react";
import { useStore } from "../../../hooks/store";
import SubSystemGraph from "../../subsystems/SubSystemGraph";
import { LoadingRise } from "../../UI/spinners/LoadingSpinners";

const dateHashMap = [
  { name: "Σήμερα", id: 1 },
  { name: "Χθές", id: 2 },
  { name: "Τελευταίες 7 μέρες", id: 3 },
  { name: "Τελευταίες 30 μέρες", id: 4 },
];
const HorizontalGraph = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl_1, setAnchorEl_1] = useState(null);

  const [typeActivated, setTypeActivated] = useState(null);
  const [sensorActivated, setSensorActivated] = useState("");
  const [periodActivated, setPeriodActivated] = useState({
    id: 2,
    name: dateHashMap[parseInt("2") - 1].name,
  });

  const [loadingStats, setLoadingStats] = useState(false);

  const [statsData, setStatsData] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [filtersVars, setFiltersVars] = useState([]);
  const [varsList, setVarsList] = useState([]);

  const sensorsLengthRef = useRef(null);
  const [state, dispatch] = useStore();
  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    if (state.types.length > 0 && state.sensors.length > 0) {
      let typeIdActive = -1;
      for (let i = 0; i < state.types.length; i++) {
        if (state.types[i].isActive) {
          typeIdActive = state.types[i].id;
          break;
        }
      }

      if (
        typeIdActive === -1 ||
        typeIdActive === "8" ||
        typeIdActive === "9" ||
        typeIdActive === "10"
      ) {
        dispatch("CLICKED_TYPE", "5");
        return;
      }

      if (
        state.sensors === sensorsLengthRef.current &&
        (typeActivated === typeIdActive || typeIdActive === -1)
      )
        return;
      setTypeActivated(typeIdActive);
      sensorsLengthRef.current = state.sensors.length;

      const sensors = state.sensors.filter(
        (sensor) => sensor.typeId === typeIdActive
      );

      const tempVarList = [];
      if (sensors.length > 0 && sensors[0].typeId !== "9") {
        for (let i = 0; i < sensors.length; i++) {
          for (let y = 0; y < sensors[i].metrics.length; y++) {
            if (!tempVarList.includes(sensors[i].metrics[y].content))
              tempVarList.push(sensors[i].metrics[y].content);

            if (sensors[i].typeId === "8") break;
          }
        }
      }
      setVarsList(tempVarList);
    }
  }, [state.types, state.sensors]);

  useEffect(() => {
    if (typeActivated && typeActivated !== -1) callStats();

    return () => controller.abort();
  }, [typeActivated, sensorActivated, periodActivated]);

  const callStats = async () => {
    console.log(typeActivated, sensorActivated, periodActivated);
    setLoadingStats(true);
    const req = await fetch(
      `https://preveza.smartiscity.gr/api/api.php?func=stats&category=${typeActivated}&sensor=${sensorActivated}&period=${periodActivated.id}`,
      { signal }
    ).catch((error) => console.warn(error));

    if (req === undefined || !req.ok) {
      setLoadingStats(false);
      return;
    }

    const responseData = await req.json();
    console.log(responseData);
    setStatsData(responseData);
    setLoadingStats(false);
  };

  const searchInputHandler = (input) => setSearchInput(input);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl_1(null);
  };

  const clickSubSystemHandler = (option) => {
    console.log(option);
    setAnchorEl(null);
    if (typeActivated !== option) {
      dispatch("CLICKED_TYPE", option);
      dispatch("DISABLE_SENSORS");
      setTypeActivated(option);
      setSensorActivated("");
      clearVarsHandler();
    }
  };

  const handleClickListItem2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const clearVarsHandler = () => {
    setFiltersVars([]);
  };

  const varsHandler = (variable) => {
    if (!filtersVars.includes(variable))
      setFiltersVars((prev) => prev.concat(variable));
    else setFiltersVars((prev) => prev.filter((item) => item !== variable));
  };

  const handleClickListItem3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const clickPeriodHandler = (option) => {
    console.log(option);
    setAnchorEl3(null);
    if (periodActivated.id !== option) {
      setPeriodActivated({
        id: option,
        name: dateHashMap[parseInt(option) - 1].name,
      });
    }
  };

  const handleClickListItem_1 = (event) => {
    setAnchorEl_1(event.currentTarget);
  };

  const clickSubSystemHandler_1 = (option) => {
    console.log(option);
    setAnchorEl_1(null);

    if (sensorActivated !== option) setSensorActivated(option);
  };

  console.log(periodActivated.name);
  return (
    <Fragment>
      <div className={classes.subsystem_container}>
        <div className={classes.search_subsystem_layout}>
          {/*<img*/}
          {/*  src={search_icon}*/}
          {/*  alt="search icon"*/}
          {/*  className={classes.search_style}*/}
          {/*/>*/}
          {/*<InputOnChange*/}
          {/*  inputPlaceHolder={"Αναζήτηση"}*/}
          {/*  className={classes.search_input}*/}
          {/*  type="search"*/}
          {/*  setSearch={searchInputHandler}*/}
          {/*/>*/}
          <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
            Γραφήματα Δεδομένων Υποσυστημάτων
          </h2>
          <div className={classes.select_place}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ textTransform: "none" }}
              onClick={handleClickListItem}
            >
              Υποσύστημα&nbsp;
              <span
                className={
                  anchorEl
                    ? [classes.rotate, classes.animation].join(" ")
                    : classes.rotate
                }
              >
                &#8250;
              </span>
            </Button>

            <Popover
              id={"simple-popover"}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <PopupMenuSubSystem
                subsystems={state.types.filter(
                  (item) =>
                    item.id !== "8" &&
                    item.id !== "9" &&
                    item.id !== "10" &&
                    //item.id !== "11" &&
                    item.id !== "13"
                )}
                setOption={clickSubSystemHandler}
              />
            </Popover>

            {/*  /!* -------------------------------------------- *!/*/}

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ textTransform: "none" }}
              onClick={handleClickListItem_1}
            >
              Αισθητήρα&nbsp;
              <span
                className={
                  anchorEl_1
                    ? [classes.rotate, classes.animation].join(" ")
                    : classes.rotate
                }
              >
                &#8250;
              </span>
            </Button>

            <Popover
              id={"simple-popover"}
              open={Boolean(anchorEl_1)}
              anchorEl={anchorEl_1}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <PopupMenuSubSystem
                subsystems={state.sensors
                  .filter((item) => item.typeId === typeActivated)
                  .map((item) => ({ ...item, name: item.title }))}
                setOption={clickSubSystemHandler_1}
              />
            </Popover>

            {/*  /!* -------------------------------------------- *!/*/}

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClickListItem2}
            >
              <Badge
                badgeContent={filtersVars.length}
                max={999}
                color="secondary"
                style={{ textTransform: "none" }}
              >
                Μεταβλητή&nbsp;
                <span
                  className={
                    anchorEl2
                      ? [classes.rotate, classes.animation].join(" ")
                      : classes.rotate
                  }
                >
                  &#8250;
                </span>
              </Badge>
            </Button>
            <Popover
              id={"simple-popover"}
              open={Boolean(anchorEl2)}
              anchorEl={anchorEl2}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <PopupMenuVars
                varsList={varsList}
                setVars={varsHandler}
                filtersVars={filtersVars}
                close={handleClose}
                clearVars={clearVarsHandler}
                type={typeActivated}
              />
            </Popover>

            {/*  /!* -------------------------------------------- *!/*/}

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ textTransform: "none" }}
              onClick={handleClickListItem3}
            >
              {periodActivated.name}&nbsp;
              <span
                className={
                  anchorEl
                    ? [classes.rotate, classes.animation].join(" ")
                    : classes.rotate
                }
              >
                &#8250;
              </span>
            </Button>

            <Popover
              id={"simple-popover"}
              open={Boolean(anchorEl3)}
              anchorEl={anchorEl3}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <PopupMenuSubSystem
                subsystems={dateHashMap}
                setOption={clickPeriodHandler}
              />
            </Popover>
          </div>
        </div>
      </div>

      {!loadingStats && (
        <SubSystemGraph
          stats={statsData}
          type={periodActivated.id}
          searchInput={searchInput}
          filtersVars={filtersVars}
        />
      )}

      {loadingStats && <LoadingRise />}
    </Fragment>
  );
};

export default HorizontalGraph;
