import classes from "./VerticalSidePanel.module.css";
import { useState, useEffect } from "react";


const VerticalSidePanel = (props) => {
  
  const queryParameters = new URLSearchParams(window.location.search)
  const [typeId, setparam] = useState(queryParameters.get("typeId"));
 //setparam(queryParameters.get("typeId"));
 useEffect(() => {

    setparam(queryParameters.get("typeId"));
 
});
  return (
    
    <div
      className={
        props.show
          ? [
              classes.vertical_side_panel,
              classes.side_panel_height,
              props.className,
            ].join(" ")
          : [classes.vertical_side_panel, props.className].join(" ")
      }
    >
      <span
        onClick={() => props.close()}
        className={classes.close_button_sticky}
      >
        <p className={classes.close_button}>x</p>
      </span>
      {props.children}
      <br/>
      {typeId == 13 &&
        <span>Ζύγιση στην οποία φαίνεται το αναγνωριστικό του αισθητήρα πραγματοποιείται σε κάδο με RFID tag και εμφανίζεται το καθαρό βάρος των απορριμμάτων. Ζύγιση στην οποία δεν φαίνεται το αναγνωριστικό του αισθητήρα πραγματοποιείται σε κάδο χωρίς RFID tag και εμφανίζεται το μικτό βάρος κάδου και απορριμμάτων</span>
      }
    </div>
  );
};

export default VerticalSidePanel;
