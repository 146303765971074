import classes from "./Entity.module.css";

const Entity = (props) => {
  return (
    <div className={classes.entity_container}>
      {/* <div className={classes.title_container}>
        {props.icon && (
          <i className={props.icon} style={{ fontSize: "20px" }} />
        )}
      </div> */}
      <i className={props.icon} style={{ fontSize: "20px", float: "left" }} />
      <div className={classes.information}>
        <span className={classes.entity_title}>{props.title}</span>
        <p className={classes.entity_addres}>{props.address}</p>
        {props.icons.map(
          (icon, index) =>
            icon && <img key={index} src={icon} alt={"info icons"} />
        )}
      </div>
    </div>
  );
};
export default Entity;
