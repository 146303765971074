import classes from "./Entity.module.css";

const Entity = (props) => {
  return (
    <div
      className={[classes.entity, classes.gridContainer, props.className].join(
        " "
      )}
    >
      <div className={classes.icon}>
        {props.icon && <img src={props.icon} alt="icon" width="32px" />}
      </div>
      <div className={classes.content}>{props.content}</div>
      <div className={classes.value} style={props.styleValue}>
        {props.value}
      </div>
    </div>
  );
};

export default Entity;
