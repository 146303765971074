import classes from "./RadarChart.module.css";
import { Radar, PolarArea } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { LoadingCircle, LoadingClock } from "../../UI/spinners/LoadingSpinners";

const data = {
  labels: [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ],
  datasets: [
    {
      label: "",
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
      order: 1,
    },
    {
      label: "0",
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
      type: "polarArea",
      order: 0,
    },
  ],
};

const initialRadar = {
  vehCounter: { ...data },
  avgSpeed: { ...data },
};

const RadarChart = (props) => {
  const [radarData, setRadarData] = useState(initialRadar);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currTime = new Date().toISOString().split("T")[0];
    setLoading(true);
    fetch(
      `https://preveza.smartiscity.gr/api/api.php?func=traffic_front&from=${currTime}&to=${currTime}&sensor=${props.sensorId}`
    )
      .then((response) => response.json())
      .then((fetchData) => {
        console.log("Πέρασα", fetchData);

        console.log(fetchData);
        if (!fetchData || fetchData.length === 0) {
          setLoading(false);
          setRadarData(initialRadar);
          return;
        }

        setRadarData((prev) => {
          const currVehCounter = new Array(24).fill(0);
          const currAvgSpeed = new Array(24).fill(0);

          console.log(fetchData[0].Timestamp);

          fetchData.map((item) => {
            currVehCounter[new Date(item.Timestamp).getHours()] = Number(
              item.VehiclesCount
            );
            currAvgSpeed[new Date(item.Timestamp).getHours()] = Number(
              item.avgSpeed
            );
          });
          setLoading(false);
          return {
            vehCounter: {
              ...prev.vehCounter,
              datasets: [
                { ...prev.vehCounter.datasets[0], data: currVehCounter },
                { ...prev.vehCounter.datasets[1] },
              ],
            },
            avgSpeed: {
              ...prev.avgSpeed,
              datasets: [
                { ...prev.avgSpeed.datasets[0], data: currAvgSpeed },
                { ...prev.avgSpeed.datasets[1] },
              ],
            },
          };
        });
      });
  }, [props.sensorId]);

  return (
    <section className={classes.chart_section}>
      <h1>Ιστορικό - 24 ώρες</h1>

      <h2>Αριθμός οχημάτων</h2>
      <div
        className={classes.chart_box}
        style={{ backgroundColor: props.clockColor }}
      >
        {/*<span>Αριθμός οχημάτων</span>*/}
        {loading && <LoadingClock />}
        <Radar
          data={radarData.vehCounter}
          type={Radar}
          className={
            loading
              ? `${classes.chart_style} ${classes.chart_loadingStyle}`
              : `${classes.chart_style}`
          }
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            animation: {
              duration: 2000,
            },
            scales: {
              r: {
                ticks: {
                  max: 450,
                  maxTicksLimit: 10,
                  display: false,
                },
                angleLines: {
                  display: true,
                },
                scaleLabel: {
                  display: true,
                },
                pointLabels: {
                  display: true,
                },
              },
            },
          }}
        />
      </div>

      <h2>Μέση ταχύτητα οχημάτων</h2>
      <div
        className={classes.chart_box}
        style={{ backgroundColor: props.clockColor }}
      >
        {/*<span>Μέση ταχύτητα οχημάτων</span>*/}
        {loading && <LoadingClock />}
        <Radar
          data={radarData.avgSpeed}
          type={Radar}
          className={
            loading
              ? `${classes.chart_style} ${classes.chart_loadingStyle}`
              : `${classes.chart_style}`
          }
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            animation: {
              duration: 2000,
            },
            scales: {
              r: {
                ticks: {
                  max: 450,
                  maxTicksLimit: 10,
                  display: false,
                },
                angleLines: {
                  display: true,
                },
                scaleLabel: {
                  display: true,
                },
                pointLabels: {
                  display: true,
                },
              },
            },
          }}
        />
      </div>
    </section>
  );
};

export default RadarChart;

//import classes from "./RadarChart.module.css";
// import { Radar, PolarArea } from "react-chartjs-2";
// import { useEffect } from "react";
//
// const data = {
//   labels: [
//     "00:00",
//     "01:00",
//     "02:00",
//     "03:00",
//     "04:00",
//     "05:00",
//     "06:00",
//     "07:00",
//     "08:00",
//     "09:00",
//     "10:00",
//     "11:00",
//     "12:00",
//     "13:00",
//     "14:00",
//     "15:00",
//     "16:00",
//     "17:00",
//     "18:00",
//     "19:00",
//     "20:00",
//     "21:00",
//     "22:00",
//     "23:00",
//   ],
//   datasets: [
//     {
//       label: "",
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(75, 192, 192, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//         "rgba(255, 159, 64, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };
//
// const RadarChart = () => {
//   useEffect(() => {
//     fetch("https://efa-heraklion-test.dotsoft.gr/api/el/timeline")
//       .then((response) => response.json())
//       .then((data) => {
//         console.log("Πέρασα", data);
//       });
//   }, []);
//
//   return (
//     <section className={classes.chart_section}>
//       <h1>Ιστορικό - 24 ώρες</h1>
//       <div className={classes.chart_box}>
//         <span>TEST 2</span>
//         <PolarArea
//           data={data}
//           type={PolarArea}
//           className={classes.chart_style}
//           options={{
//             plugins: {
//               legend: {
//                 display: false,
//               },
//             },
//             animation: {
//               duration: 0,
//             },
//             scales: {
//               r: {
//                 angleLines: {
//                   display: true,
//                 },
//                 scaleLabel: {
//                   display: true,
//                 },
//                 pointLabels: {
//                   display: true,
//                 },
//               },
//             },
//           }}
//         />
//       </div>
//       <div className={classes.chart_box}>
//         <span>TEST 2</span>
//         <Radar
//           data={data}
//           className={classes.chart_style}
//           type={"Radar"}
//           options={{
//             plugins: {
//               legend: {
//                 display: false,
//               },
//             },
//             animation: {
//               duration: 0,
//             },
//           }}
//         />
//       </div>
//     </section>
//   );
// };
//
// export default RadarChart;
