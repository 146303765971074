import classes from "./SubSystemGraph.module.css";
import ChartLine from "./../forms/chartjs/ChartLine";

const SubSystemGraph = (props) => {
  return (
    <div className={classes.graphs_container}>
      {props.stats
        .filter((item) => {
          if (props.searchInput) {
            if (
              item.description
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
                .includes(
                  props.searchInput
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                )
            )
              return item;
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (props.filtersVars.length === 0) return item;
          let included = false;

          for (let y = 0; y < props.filtersVars.length; y++) {
            if (item.description === props.filtersVars[y]) {
              included = true;
              break;
            }
          }

          if (included) return item;
        })
        .map((item, index) => (
          <ChartLine key={index} data={item} type={props.type} />
        ))}
    </div>
  );
};

export default SubSystemGraph;
