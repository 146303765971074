import classes from "./WelcomeMessage.module.css";
import { useHistory } from "react-router-dom";
import SvgTerms from "./../../assets/images/Terms";
import SvgTermsofuse2 from "./../../assets/images/Termsofuse2";

const WelcomeMessage = (props) => {
  const history = useHistory();
  return (
    <div className={classes.welcome_placeholder}>
      <p>
        Ο Δήμος {props.municipality} σας καλωσορίζει στην Πλατφόρμα Εξυπνης
        Πόλης.
      </p>
      <p> Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.</p>
      <div>
      <div
          style={{ marginBottom: 5 }}
          onClick={() => history.push("/terms-of-use")}
        >
          <SvgTermsofuse2 width={35} />
         
       <p>Δήλωση Προσβασιμότητας</p>
       </div>
        </div>
    </div>
  );
};

export default WelcomeMessage;
