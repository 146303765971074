import React, { useState, Fragment, useEffect } from "react";

import HorizontalSidePanel from "./../../components/UI/panel/HorizontalSidePanel";
import classes from "./MapContainer.module.css";
import VerticalSidePanel from "./../../components/UI/panel/VerticalSidePanel";
import Horizontal from "../../components/forms/horizontal/Horizontal";
import Vertical from "../../components/forms/vertical/Vertical";

let listener = null;
const MapContainer = (props) => {
  const [showInfoPanel, setShowInfoPanel] = useState(false);

  const close = () => {
    setShowInfoPanel(false);
  };

  const showAnimation = (value) => {
    if (showInfoPanel && value) {
      clearTimeout(listener);
      setShowInfoPanel(false);
      listener = setTimeout(() => setShowInfoPanel(true), 250);
    } else {
      setShowInfoPanel(true);
    }
  };

  useEffect(() => {
    return () => clearTimeout(listener);
  }, []);

  console.log(props);

  return (
    <Fragment>
      <HorizontalSidePanel>
        <Horizontal showAnimation={showAnimation} close={close} />
      </HorizontalSidePanel>
      <VerticalSidePanel show={showInfoPanel} close={close}>
        <Vertical />
      </VerticalSidePanel>
      <div className={props.mapFrame ? classes.wrapper_full : classes.wrapper}>
        {React.cloneElement(props.children, {
          showAnimation,
        })}
      </div>
    </Fragment>
  );
};

export default MapContainer;
