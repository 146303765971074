import classes from "./BoxOnlyDetails.module.css";
import { Fragment, useEffect, useState } from "react";
import Title from "../../UI/box/title/Title";
import CustomSlider from "../../slider/CustomSlider";
import SubTitle from "../../UI/box/subtitle/SubTitle";
import Entity from "../../UI/box/entity/Entity";
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";

const BoxOnlyDetails = ({
  fetchDataLink,
  title,
  typeId,
  showSubTitle,
  maxEntities,
  mediaPrefix,
}) => {
  const [data, setData] = useState(null);

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(fetchDataLink, { signal });
      if (!request.ok) return;
      const fetchedData = await request.json();

      let tempItems = [];
      const finallyGroup = [];

      fetchedData.group.map((item, index) => {
        tempItems.push(item);
        if (
          tempItems.length === maxEntities ||
          index === fetchedData.length - 1
        ) {
          const currentItems = tempItems;
          tempItems = [];
          finallyGroup.push(currentItems);
        }
      });

      setData({ ...fetchedData, group: finallyGroup });
    };
    fetchData().catch((error) => console.error(error));
    return () => controller.abort();
  }, []);

  return (
    <Fragment>
      <Title
        icon={data?.sensortype_icon || ``}
        title={title}
        link={`/map?typeId=${typeId}`}
        infoContext={data?.tooltip || ``}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={typeId}
      />

      {data && data.group.length > 0 && (
        <CustomSlider>
          {data.group.map((item) => (
            <>
              {showSubTitle && <SubTitle>{item.group_name}</SubTitle>}
              <div>
                {item.map((datum, index) => (
                  <>
                    <Entity
                      key={index}
                      icon={mediaPrefix + datum.sensorvar_icon}
                      content={datum.group_name}
                      value={`${datum.group_val}\xa0${datum.sensorvar_unit}`}
                    />
                  </>
                ))}
              </div>
            </>
          ))}
        </CustomSlider>
      )}
      {(!data || data.group.length === 0) && <LoadingDot />}
    </Fragment>
  );
};

export default BoxOnlyDetails;
