import DotLoader from "react-spinners/DotLoader";
import CircleLoader from "react-spinners/CircleLoader";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import { ClockLoader } from "react-spinners";

const override = css`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
`;

const overrideBig = css`
  position: absolute;
  top: calc(50% - 23.5px);
  left: calc(50% - 135px);
`;

export const LoadingDot = () => {
  return <DotLoader color={"white"} loading size={60} css={override} />;
};

export const LoadingCircle = () => {
  return <CircleLoader color={"white"} loading size={50} css={override} />;
};

export const LoadingRise = () => {
  return <RiseLoader color={"#e3728f"} loading size={50} css={overrideBig} />;
};

export const LoadingClock = () => {
  return <ClockLoader color={"white"} loading size={50} css={override} />;
};
